.background-image {
  width: 100vw;
  height: 100vh;
}

.login-form-div {
  position: fixed;
  top: 25%;
  left: 31%;
  background-color: rgba(255, 255, 255, 0.368);
  width: 40%;
  height: 300px;
  border-radius: 8px;
  padding: 25px;
  text-align: center;

}

.login-head {
  padding-left: 26px;
  margin-bottom: 25px;
  font-weight: 600;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media (max-width: 768px) {
  .login-form-div {
    width: 70%;
    left: 10%;
  }
}

@media (max-width: 480px) {
  .login-form-div {
    height: auto;
    top: 30%;
  }
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group-input {
  width: 100%;
  height: 30px;
  border: .5px black;
  border-radius: 5px;
  padding-left: 10px;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.login-icon-div {
  display: flex;
  align-items: center;
}

.glow-on-hove {
  padding: 10px 20px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  margin: 20px;
  width: 80px;
}

.glow-on-hove:before {
  content: "";
  background: linear-gradient(45deg, #ffff00,
    );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hove:active {
  color: #000;
}

.glow-on-hove:active:after {
  background: transparent;
}

.glow-on-hove:hover:before {
  opacity: 1;
}

.glow-on-hove:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}