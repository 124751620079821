.main-card{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
    height: 65%;
}
.card-style {
width: 280px !important;
gap: 10px !important;
height: 120px !important;
transition: transform 0.3s ease !important;

}
.card-style-image {
  width: 300px !important;
  gap: 10px !important;
  height: 230px !important;
  transition: transform 0.3s ease !important;
  
  }

  
  .card-style:hover {
    transform: scale(1.05) !important; /* You can adjust the scaling factor as needed */
  }

  .pagination-div{

    margin-top: 20px;
  }

  .pagination-con{
    display: flex !important;
    justify-content: center !important;
      align-items: center !important;
  }

  .branch-admin-div{
    margin-top: 60px;
  }
  .admin-branch{
    margin-bottom: 20px;
  }
  .branch-name{
    font-size: 20px;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }