.action-button-student{
    width: 92px;
    display: flex;
    justify-content: space-around;
}
.action-updates{
    color: rgb(22, 95, 213);
    font-size: 28px;
    cursor: pointer;
    margin-left: 10px;
}
.search-container{
    margin-bottom: 20px;
}
.filters{
    display: flex;
    justify-content: space-around;
}
.toggle-btn {
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
  }
  
  .toggle-handle {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease;
  }
  
  .toggle-btn.active .toggle-handle {
    transform: translateX(30px);
  }
  