.Teacher-main-div {
    height: 100%;
    overflow: auto;
}

.names {
    color: #717481 !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600  !important;
    line-height: normal !important;
    letter-spacing: -0.14px !important;
}

.action-button {
    display: flex;
    justify-content: space-around;
}

.action-delete {
    color: red;
    font-size: 28px;
    cursor: pointer;

}

.action-update {
    color: rgb(22, 95, 213);
    font-size: 28px;
    cursor: pointer;
}

.swal2-title {
    font-size: 18px;
}

.css-uhb5lp {
    max-width: 660px !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    max-width: 700px !important;

}
.select-forms {
    width: 280px;
    flex-shrink: 0;
    border-radius: 9px;
    border: 1px solid var(--neutral-300, #EFF0F6);
    background: var(--neutral-100, #FFF);
    box-shadow: 0px 2px 6px 0px rgba(19, 18, 66, 0.07);
    padding: 8px;
    height: 33px !important;
}
.add-teacher-div{
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 34px;
}


.button-83 {
    background-color: transparent;
    background-image: linear-gradient(to bottom, #fff, #f8eedb);
    border: 0 solid #e5e7eb;
    border-radius: .5rem;
    box-sizing: border-box;
    color: #482307;
    cursor: pointer;
    font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    margin: 0;
    outline: 2px solid transparent;
    padding: 1rem 1.5rem;
    text-align: center;
    text-transform: none;
    transition: all .1s cubic-bezier(.4, 0, .2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    box-shadow: -6px 8px 10px rgba(81, 41, 10, 0.1), 0px 2px 2px rgba(81, 41, 10, 0.2);
}

.button-83:active {
    background-color: #f3f4f6;
    box-shadow: -1px 2px 5px rgba(81, 41, 10, 0.15), 0px 1px 1px rgba(81, 41, 10, 0.15);
    transform: translateY(0.125rem);
}

.button-83:focus {
    box-shadow: rgba(72, 35, 7, .46) 0 0 0 4px, -6px 8px 10px rgba(81, 41, 10, 0.1), 0px 2px 2px rgba(81, 41, 10, 0.2);
}