.select-div{
min-width: 280px !important;
min-height: 50px !important;
border-radius: 46px;
    border: 1px solid var(--neutral-300, #EFF0F6);
    background: var(--neutral-100, #FFF);
    box-shadow: 0px 2px 6px 0px rgba(19, 18, 66, 0.07);
}
#demo-multiple-chip{
    padding: 0 0 !important;
}