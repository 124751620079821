.paid-btn{
    background-color: rgb(101, 217, 101) !important;
    color: white !important;
    width: 100px !important;

}
.lop-btn{
    background-color: rgb(217, 101, 101) !important;
    color: white !important;
    width: 100px !important;
    margin-left: 25px !important;
}