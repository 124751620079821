/* Default styles for large screens */
.feedback-img {
    width: 100vw;
    height: 100vh;
}

.feedback-form-div {
    position: fixed;
    top: 30%;
    left: 30%;
    background-color: rgba(1, 1, 1, 0.153);
    width: 500px;
    height: 350px;
    border-radius: 20px;
    padding: 40px;
}

.feed-head {
    margin-bottom: 30px;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.feedback-input {
    width: 100%;
    height: 30px;
    border: none;
    border-radius: 5px;
}
.feedback-input-message{
    width: 100%;
    height: 80px;
    border: none;
    border-radius: 5px; 
}

/* Responsive styles for smaller screens */
@media (max-width: 900px) {
    .feedback-form-div {
        width: 80%;
        left: 10%;
        padding: 20px;
    }
}
@media (max-width: 768px) {
    .feedback-form-div {
        width: 80%;
        left: 10%;
        padding: 20px;
    }
}

@media (max-width: 576px) {
    .feedback-form-div {
        width: 90%;
        left: 5%;
        padding: 10px;
    }
    .feedback-input {
        height: 25px;
    }
}
