.sidebar{
    width: 113px;
    height: 50px;
    margin-left: 51px;
    margin-top: -43px;
}
.dashboard-nav{
    background-color: #f0f0f1 !important;
    color: black !important;
    box-shadow: none !important;
}
.box-div{
    width: 100% !important;
    height: 100% !important;

}
.css-1v5u2yv{
    /* background-color: #f0f0f1 !important; */
}
.card-div{
    padding: 90px 20px 20px 20px;
    height: 100%;
}
.icon-style{
    min-width: 34px !important;
}
.log-out-button{

}
.log-out-button{

    display: flex;
    align-items: center;
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff !important;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

}

.nav-button{
display: flex;
justify-content: space-between;
width: 100%;
    align-items: center;
}