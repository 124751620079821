.react-calendar__tile--active {
  background: none !important;
  color: rgb(0, 0, 0) !important;
}

.react-calendar__tile--now {
  background: none !important;
  ;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* Assuming a week view */
  gap: 5px;
}

.calendar-day {
  border: 1px solid #ccc;
  padding: 7px;
  height: 90px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}

.date {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.calender-p {
  margin: 0 !important;
  margin-top: 5px;
  margin-bottom: 2px;
}

.topic {
  white-space: nowrap;
  width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status {
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.9em;
}

.present {
  background-color: #a8f0c6;
  /* Green color for 'present' status */
}

.absent {
  background-color: #f8b8b8;
  /* Red color for 'absent' status */
}

.green {
  background-color: #00800091;
}

.red {
  background-color: #d84d2fcc;
}

.white {
  background-color: white;
}

.arrow-btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.main-card-calender {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
  height: 39%;
  margin-top: 50px;
}

.card-style-calender {
  width: 210px !important;
  gap: 10px !important;
  height: 120px !important;
  transition: transform 0.3s ease !important;

}
.fullscreen-image{
  width: 100%;
}
@media only screen and (max-width: 800px) {
  .card-style-calender {
    width: 100% !important;
    height: auto !important;
  }
}

.card-style-calender:hover {
  transform: scale(1.05) !important;
}

.card-style-calender-div {
  width: 100% !important;
  gap: 10px !important;
  /* height: 120px !important;   */
  transition: transform 0.3s ease !important;
}