.main-div{
    
}
.form-main-div {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.form-div {
    border-radius: 34px;
    border: 1px solid #eff0f6;
    box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
    background-color: #faf8f8;
    display: flex;
    flex-direction: column;
    padding: 20px 40px 40px 40px;
    width: 660px;
    margin: auto;

}

.from-scroll-div {
  
}

.form-div-head {
    border-radius: 18px;
    box-shadow: 0px 12px 24px 0px rgba(20, 20, 43, 0.04),
        0px -2px 4px 0px rgba(20, 20, 43, 0.02),
        0px 3px 14px 0px rgba(74, 58, 255, 0.03);
    background-color: var(--neutral-100, #fff);
    display: flex;
    height: 70px;
    flex-direction: column;
    width: 80%;
    text-align: center;
    margin: 40px 0 50px 90px;
}
.joint-div{
    display: flex;
    gap: 37px;

}
.form-div-text {}

.form-div-text-head {
    color: var(--neutral-800, #170F49);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.form-div-text-paragraph {
    color: #6F6C90;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    /* 166.667% */
}

.line {
    background: #000000;
}

.form-input {
    width: 284px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 46px;
    border: 1px solid var(--neutral-300, #EFF0F6);
    background: var(--neutral-100, #FFF);
    box-shadow: 0px 2px 6px 0px rgba(19, 18, 66, 0.07);
    padding-left: 20px;
}

.label-text {
    color: var(--neutral-800, #170F49);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* /* line-height: 20px; */
}

.form-text-div {
    margin-top: 10px;
}

.error-code {
    font-size: 12px;
    margin: 5px 0 0 10px;
    color: red;
}

.heading-label {
    color: var(--neutral-800, #170F49);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
}

.select-form {
    width: 308px;
    flex-shrink: 0;
    border-radius: 9px;
    border: 1px solid var(--neutral-300, #EFF0F6);
    background: var(--neutral-100, #FFF);
    box-shadow: 0px 2px 6px 0px rgba(19, 18, 66, 0.07);
    padding:8px;
   
}
.menulist{
    padding-left: 40px !important;
}
.batch-div {}

.days {
    padding: 5px;
    color: var(--neutral-800, #040404);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: DM Sans;
}

.submit {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border-radius: 30px;
    background: linear-gradient(180deg, #9E96F2 0%, #EC31F0 100%);
    box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.18);
    width: 100px;
    height: 40px;
    border: none;
}

.submit-div {
    display: flex;
    flex-direction: row-reverse;
}
.form-batch-div{
    margin-top: 15px;
}

@media (max-width: 576px) {
    .joint-div {
        display: flex;
        gap: 20px;
        flex-direction: column;
    }
    .form-main-div {
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow-x: hidden;
    }
    .submit-div {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }
    .form-div {
        padding: 12px;
    }
}