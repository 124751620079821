
.navbar-div {
    position: fixed;
    top: 2%;
    left: 15px;
    width: 100%;
  }
  
  .logo {
    width: 15%;
  }
  
  .navbar-div-item {
    display: flex;
    justify-content: space-between;
    padding-right: 45px;
  }
  
  .navbar-items {
    display: flex;
    width: 169px;
    justify-content: space-between;
    margin-top: 33px;
  }
  .navbar-text{
    color: white;
    font: normal 600 16px 'Montserrat', sans-serif;
  }
  .black-text{
    color: black !important;
    font: normal 600 16px 'Montserrat', sans-serif;
  }
  @media (max-width: 768px) {
    .navbar-div-item  {
 
    }
}