.about-image {
    width: 100%;
    height: 345px;
    top: 0px;
    position: absolute;
    background: url("../../assets//images/company-banner_2-fotor-20230628115939.jpg")center center/cover no-repeat;
}

.about-heading {
    color: #fff;
    font-size: 40px;
    display: flex;
    justify-content: space-evenly;
    padding-top: 170px;
}

.about-div {
    padding-top: 192px;
    font: normal 600 34px/40px 'Montserrat', sans-serif;
    text-align: center;
}

.about-text {
    padding: 20px 50px 0 50px;
    margin-bottom: 0;
    margin-top: 0;
}

.about-text-head {
    margin-bottom: -20px;
}


.about-text-div {
    position: relative;
    float: left;
    padding: 15px 20px;
    background: #fff;

}



h1 {
    color: #333;
    font-size: 24px;
    text-align: center;
}

.about-text {
    color: #666;
    font-size: 16px;
    line-height: 1.5;

}

.section-heading {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}

.feature-list {

    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 160px;
}

.feature-list li {
    list-style: disc;
    margin-bottom: 10px;
}

.why-choose-us {
    margin-top: 20px;
}

.why-choose-us .list-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.why-choose-us .list-item i {
    color: #666;
    font-size: 18px;
    margin-right: 10px;
}

.footer {
    margin-top: 20px;
    text-align: center;
}

.footer p {
    color: #666;
    font-size: 14px;
    margin-top: 4rem;
}