.select-attendance {
    width: 100%;
    flex-shrink: 0;
    border-radius: 9px;
    border: 1px solid var(--neutral-300, #EFF0F6);
    background: var(--neutral-100, #FFF);
    box-shadow: 0px 2px 6px 0px rgba(19, 18, 66, 0.07);
    padding: 8px;
    height: 33px !important;
}

.calender-div {
    width: 100%;
    height: 100%;
    padding-top: 60px;
}

.react-calendar {
    width: 100% !important;
    height: 90% !important;


}

.attendance-list {
    padding-top: 50px;
}


.toggle-btn {
    line-height: 1 !important;
    padding: 8px !important;
}

.add-submit {
    padding-top: 20px;
    display: flex;
    flex-direction: row-reverse;

}